import * as React from "react";
import { featuresContainer, cardContainer } from "./Features.module.css";
import {
    FAQcontainer,
    teamHeading,
    greyText,
    faqList,
  } from "../web3FAQs/web3FAQs.module.css";
import Heading from "../../components/Heading/Heading";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import data from './CardContent';

const WhyJoin = () => {
  return (
    <div className={featuresContainer}>
       <div class={teamHeading}>
        <span class={greyText}>Why Join Us</span>
      </div>
      <div className={cardContainer}>
        {data.map((item)=>{
            return <FeatureCard title={item.title} description={item.description} icon={item.icon} />
        })}
      </div>
    </div>
  );
};

export default WhyJoin;
