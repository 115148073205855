import * as React from 'react'

import {
    mentorCardContainer,
    mentorImg,
    mentorText,
    mentorName,
    mentorDesignation,
    tag,
    tagFlex
} from './MentorCard.module.css'

const WMentorCard = (props) => {
    return (
        <div class={mentorCardContainer}>
            <img src={props.imgSrc} class={mentorImg} />
            <div class={mentorText}>
                <div class={mentorName}>{props.name}</div>
                <div class={mentorDesignation}>{props.designation}</div>
                <div class={tagFlex}>
                    {props.tag1 ? <div class={tag}>{props.tag1}</div> : null}
                    {props.tag2 ? <div class={tag}>{props.tag2}</div> : null}
                    {props.tag3 ? <div class={tag}>{props.tag3}</div> : null}
                </div>
            </div>
        </div>
    )
}

export default WMentorCard;