import * as React from "react";
import FAQitem from "./FAQitem";

import {
  FAQcontainer,
  teamHeading,
  greyText,
  faqList,
} from "./web3FAQs.module.css";

const FAQs = () => {
  const [faq1, setFaq1] = React.useState(true);
  const [faq2, setFaq2] = React.useState(false);
  const [faq3, setFaq3] = React.useState(false);
  const [faq4, setFaq4] = React.useState(false);
  const [faq5, setFaq5] = React.useState(false);
  const [faq6, setFaq6] = React.useState(false);
  const [faq7, setFaq7] = React.useState(false);
  const [faq8, setFaq8] = React.useState(false);
  const [faq9, setFaq9] = React.useState(false);

  return (
    <div className={FAQcontainer}>
      <div class={teamHeading}>
        <span class={greyText}>FAQ</span>s
      </div>
      <div class={faqList}>
        <FAQitem
          setFaq={setFaq1}
          show={faq1}
          faq="Who should apply?"
          text="Any Web3 startup at any stage will benefit from the program.
          But pre-product or early product startups will benefit the most."
        />
        <FAQitem
          setFaq={setFaq2}
          show={faq2}
          faq="What industry/ sector are the startups from? "
          text="Web3 Startups from all sectors and industries are invited to join. "
        />
        <FAQitem
          setFaq={setFaq3}
          show={faq3}
          faq="How do startups register? Do startups need to pay to register?"
          text="Click on the Register Now button to register for Conquest 2023. It is completely free. "
        />
        <FAQitem
          setFaq={setFaq4}
          show={faq4}
          faq="How much does the program cost?"
          text="Nothing, the acceleration program is completely free."
        />
        <FAQitem
          setFaq={setFaq5}
          show={faq5}
          faq="Is my startup’s pitch deck safe with you? "
          text="Yes absolutely. Your pitch deck will not be shared with anyone without your approval ever. "
        />
        <FAQitem
          setFaq={setFaq6}
          show={faq6}
          faq="Do I Have to build on a specific chain to be eligible?"
          text="No we support builders building on all chains, with brownie points for projects with inter-chain operability."
        />
        <FAQitem
          setFaq={setFaq7}
          show={faq7}
          faq="How many mentors do you have?"
          text="Our Top-30 startups receive personalized mentoring in the form of one-on-one sessions. 250+ mentoring sessions are conducted with over 80 mentors from various walks of the profession."
        />
        <FAQitem
          setFaq={setFaq8}
          show={faq8}
          faq="Do I have to raise funding compulsorily from the avenues provided by Conquest?"
          text="Absolutely not, we help startups, no strings attached."
        />
        <FAQitem
          setFaq={setFaq9}
          show={faq9}
          faq="Why exactly are you guys doing so much for absolutely free?"
          text="We are a group of crypto and startup enthusiasts. We love the ecosystem and just want to contribute to building this future. What we get in return, is lots of learning opportunities and awesome frens. WAGMI 🚀"
        />
      </div>
    </div>
  );
};

export default FAQs;
