// extracted by mini-css-extract-plugin
export var FAQcontainer = "web3FAQs-module--FAQcontainer--3LWe2";
export var teamHeading = "web3FAQs-module--teamHeading--EgMTj";
export var orangeText = "web3FAQs-module--orangeText--Dp1w2";
export var greyText = "web3FAQs-module--greyText--Igodm";
export var faqIcon = "web3FAQs-module--faqIcon--ujez7";
export var faqIcon2 = "web3FAQs-module--faqIcon2--78FAB";
export var faqH = "web3FAQs-module--faqH--y3HGq";
export var faqHeading = "web3FAQs-module--faqHeading--7JO+l";
export var faqText = "web3FAQs-module--faqText--bpbMU";
export var faqBox = "web3FAQs-module--faqBox--w+X57";