import * as React from 'react'

import {
    faqBox,
    faqHeading,
    faqText,
    faqH,
    faqIcon,
    faqIcon2,
} from './web3FAQs.module.css'

const FAQitem = (props) => {
    return (
        <div class={faqBox} onClick={() => props.setFaq(!props.show)}>
            <div class={faqH}>
                <div class={faqHeading}>{props.faq}</div>
                <img src="https://i.imgur.com/3Pw3UQl.png" class={props.show ? faqIcon2 : faqIcon } />
            </div>
            {props.show ? <div class={faqText}>{props.text}</div> : null}
        </div>
    )
}

export default FAQitem;