import React from "react";
import App from "./components/App";
import { Link } from "gatsby";

// styles
import "./App.css";
import "./custom.scss";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
export default function NavbarW() {
  return (
    <React.StrictMode>
      <div className="sidebar">
        <ProSidebar>
          <SidebarHeader>
            <Link to="/">
              <img
                alt=""
                src="https://user-images.githubusercontent.com/69129797/155062224-b3bc1445-e154-4f7d-a231-a06692559c80.png"
                width="200"
              />
            </Link>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem>
                Home <Link to="/" />
              </MenuItem>

              <MenuItem>
                Our Process <Link to="/process" />
              </MenuItem>

              <SubMenu title="Partners">
                <MenuItem>Sponsors and Partners<Link to="/sponsors-and-partners" /></MenuItem>
                <MenuItem>Resource Partners <Link to="/resource-partners" /></MenuItem>
                <MenuItem>In the News<Link to="/news" /></MenuItem>
              </SubMenu>


              <SubMenu title="Network">
                <MenuItem> Alumni <Link to="/alumni" /> </MenuItem>
                <MenuItem>Angels & Jury  <Link to="/angels-jury" /> </MenuItem>
                <MenuItem> Mentors <Link to="/mentors" /> </MenuItem>
              </SubMenu>
              
              
              <MenuItem>
                About Us <Link to="/about" />
              </MenuItem>

              <MenuItem>
                Web3 Track <Link to="/web3" />
              </MenuItem>

              <MenuItem>
                Newsletter <Link to="/newsletter" />
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <p>Copyright © Conquest 2023</p>
          </SidebarFooter>
        </ProSidebar>
      </div>
      <App />
    </React.StrictMode>
  );
}
