import * as React from 'react'

import {
    wmcontainer,
    wb3heading,
    wb3mentorscontainer
} from './web3mentors.module.css'

import web3list from './wb3list.js'
import WMentorCard from '../../components/WMentorCard/MentorCard'

const Web3mentors = () => {
    return(
        <div className={wmcontainer}>
             <div className={wb3heading}>Panelists and Mentors</div>
             <div className={wb3mentorscontainer}>
                {web3list.map((mentor) => (
                    <WMentorCard
                        name={mentor.name}
                        designation={mentor.designation}
                        tag1={mentor.tag1}
                        tag2={mentor.tag2}
                        imgSrc={mentor.imgSrc}
                    />
                ))}
             </div>
        </div>
    )
}

export default Web3mentors;