// extracted by mini-css-extract-plugin
export var wcontainer = "web3hero-module--wcontainer--n50Zu";
export var wcircles = "web3hero-module--wcircles--YYPvc";
export var wcontent = "web3hero-module--wcontent--rsMvH";
export var wsol = "web3hero-module--wsol--FebKC";
export var whead = "web3hero-module--whead--TGBKr";
export var orangeColour = "web3hero-module--orangeColour--2Z0G5";
export var wsub = "web3hero-module--wsub--mv+Cb";
export var wreg = "web3hero-module--wreg--WqPBI";
export var wconq = "web3hero-module--wconq--xG8D3";
export var wpartner = "web3hero-module--wpartner--PbXO+";
export var wb3h = "web3hero-module--wb3h---Gqu5";
export var wbuild = "web3hero-module--wbuild--+6i7b";