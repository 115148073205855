import * as React from "react";
import Link from "gatsby";
import {
  partnersContainer,
  partnersLogo,
  partnersLogoLink,
} from "./web3Partners.module.css";
import {
  teamHeading,
  greyText,
  partnersLogoContainer,
} from "../web3FAQs/web3FAQs.module.css";

import data from "./Partnerslist";

const Web3Partners = () => {
  return (
    <div className={partnersContainer}>
      <div class={teamHeading}>
        <span class={greyText}>Partners</span>
      </div>
      <div className={partnersLogoContainer}>
        {data.map((item) => {
          return (
            <a className={partnersLogoLink} href={item.link} target="_blank">
              <img className={partnersLogo} src={item.logo} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Web3Partners;
