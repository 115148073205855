const CardContent = [
  {
    title: "Networking Opportunities",
    description: "Get access to Conquest rich Startup Community along with industry leading mentors and venture capital firms.",
    icon: "https://user-images.githubusercontent.com/69129797/170815951-93e6655a-98ce-4ae9-b842-3fa3a600d201.png",
  },
  {
    title: "1-on-1 Mentoring Sessions",
    description: "Get one on one mentoring from leading mentors of the Web 3 space on all topics ranging from Dev Help to Tokenomics and Legal.",
    icon: "https://user-images.githubusercontent.com/69129797/170815949-5b5a2a1b-51d7-4dff-8d5b-1b8764111710.png",
  },
  {
    title: "Dev tools for web3.O buidlers",
    description: "Our resource partners ensure that our buidlers receives full support and nothing keeps them from building great businesses",
    icon: "https://user-images.githubusercontent.com/69129797/170815941-be99b8f2-8f5f-46d6-b855-5f3ab6d6104b.png",
  }
];

export default CardContent;