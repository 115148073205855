import Navbar from "./NavbarItems";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import logo from '../../../images/darkLogo.png'

const mobileNav = () => {
  document.querySelector(".sidebar").style.left = "0";
};

const App = () => {
  return (
    <header>
      <div className="wrestrict">
        <div className="nav-container">
          <div class="nav-item-mobile">
            <button onClick={mobileNav} className="hamburger">
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className="nav-area">
            <Link to="/">
              <img
                alt=""
                src={logo}
                width="200"
              />
            </Link>
            <div class="nav-item-desktop">
              <Navbar />
            </div>
          </div>
          <a href="https://lu.ma/demo-day-conquest22" target="_blank" rel="noreferrer">
            <button class="joinButton">REGISTER</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default App;
