const web3list = [
    {
        name: "Sandeep Nailwal",
        designation: "Co-founder",
        tag1: "",
        tag2: "Polygon",
        imgSrc: "https://user-images.githubusercontent.com/69129797/170811588-adff3060-9352-47a3-9ea6-179a3afbd312.png"
    },
    {
        name: "Akshay BD",
        designation: "Co-founder",
        tag1: "Superteam DAO",
        tag2: "",
        imgSrc: "https://user-images.githubusercontent.com/69129797/170811589-afe9ba4d-054e-4824-b337-6240960527cb.png"
    },
    {
        name: "Vijay Kalangi",
        designation: "Co-founder",
        tag1: "SolRazr",
        tag2: "",
        imgSrc: "https://user-images.githubusercontent.com/69129797/170811585-b6378072-b9c9-44cb-84c0-343dc2243e17.png"
    },
    {
        name: 'Navin',
        designation: 'Co-Founder',
        tag1: 'Samudai.xyz',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/69129797/182013459-184c5850-b390-4322-88da-dfcb66187390.png'
    },
    {
        name: 'Kushagra Agarwal',
        designation: 'Co-Founder',
        tag1: 'Samudai.xyz',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/69129797/182424049-bb319494-a654-4538-a260-88e0fd166ee3.png'
    },
    {
        name: 'Aravindh Kumar',
        designation: 'Co-founder',
        tag1: 'Arcana Network',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/86413540/174585949-47335f79-f55a-4f2c-a813-a63902289a0c.png'
    },
    
    {
        name: 'Prashant Maurya',
        designation: 'Co-Founder & CEO',
        tag1: 'Spheron',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/86413540/174585945-2e8c0a6b-1090-4ae9-926b-af7c4bfdbadc.png'
    },
    
    {
        name: 'Nandit Mehra',
        designation: 'Founder',
        tag1: 'Lighthouse',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/86413540/174586570-dfe16a99-8d22-4424-a7fb-447a80a3ccc4.png'
    },
    {
        name: 'Kush Ratna Gupta',
        designation: 'Co-Founder & CEO',
        tag1: 'Komet',
        tag2: '',
        imgSrc: 'https://user-images.githubusercontent.com/86413540/174586718-5790d005-bf1b-4bb6-966c-f0e89cf3d82a.png'
    },
    
    
  ];
  
  export default web3list;