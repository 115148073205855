import * as React from "react";
import Features from "../sections/Features/Features";
import FAQs from "../sections/web3FAQs/web3FAQs";
import RegBanner from "../sections/RegBanner/RegBanner";
import Footer from "../sections/web3Footer/web3Footer";
import Web3hero from "../sections/web3hero/web3hero";
import Partners from "../sections/web3Partners/web3Partners";
import "../fonts/stylesheet.css";
import NavbarW from "../sections/NavbarW/NavbarW";
import Web3text from "../sections/web3text/web3text";
import Web3mentors from "../sections/web3mentors/web3mentors";
import Web3Timeline from "../sections/Web3Timeline/Timeline";
import WhyJoin from "../sections/WhyJoin/Features";
import { Helmet } from "react-helmet";

// styles
const text = {
  fontFamily: "Manrope",
  background: "#020202",
};

// markup
const MediaPage = () => {
  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <NavbarW />
      <Web3hero />
      <Web3text
        heading="What is the web3.0 track?"
        text="India is poised to be at the center of the Web3.0 revolution this time around. With the vision to fuel this revolution, Conquest has conceptualized a Web3 track; providing  startups with world-class mentors and top-notch resources. This program is designed to help the best founders build great businesses, extensive networks and raise funds."
      />
      <Features />
      <Web3Timeline />
      <Partners />
      <Web3mentors />
      <WhyJoin />
      <FAQs />
      <RegBanner />
      <Footer />
    </main>
  );
};

export default MediaPage;
