const web3list = [
  {
    link: "https://buidlerstribe.com/",
    logo: "https://user-images.githubusercontent.com/69129797/166976598-5c08f66a-8220-46a9-8e3b-d3eb17bc85a6.png",
  },
  {
    link: "https://solrazr.com/",
    logo: "https://user-images.githubusercontent.com/69129797/166976592-e3f398bc-8864-4389-9a1a-979b0ed7ff36.png",
  },
  {
    link:"https://www.theproduct.house/",
    logo: "https://user-images.githubusercontent.com/69129797/172839504-83d80675-5759-4464-be0a-cdfb0cdc9b37.svg"
  },
  {
    link:'https://samudai.xyz/',
    logo: 'https://user-images.githubusercontent.com/69129797/182013461-743cf5fb-be5b-4aca-84b2-76f27629c2c3.png'
  },
  {
    link:'https://spheron.network/',
    logo: 'https://user-images.githubusercontent.com/69129797/174584405-032c9e0e-6903-45d1-8a42-8c0167e78a37.png'
  },
  {
    link:'https://arcana.network/',
    logo: 'https://user-images.githubusercontent.com/86413540/174583965-669baba1-266e-42a4-a1cc-1b10c38fcfe9.png'
  },
  
  {
    link:'https://lighthouse.storage/',
    logo: 'https://user-images.githubusercontent.com/69129797/175258576-5dd7503a-fc75-49fc-87f0-c0975840384c.png'
  }
];

export default web3list;
