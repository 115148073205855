const CardContent = [
  {
    title: "IDO & INO Launchpad",
    description: "Building on Solana? Get a chance to plan your INO and IDO on SolRazr's Launchpad.",
    icon: "https://user-images.githubusercontent.com/69129797/170815952-830bf6a8-2418-4eec-b5f3-6311763dd97f.png",
  },
  {
    title: "Resources worth $100k+",
    description: "Get access to world class resources like Notion, AWS, Freshworks, Hubspot & more for free.",
    icon: "https://user-images.githubusercontent.com/69129797/170815944-26d62c66-3c0a-45cf-98b9-631b829ec155.png",
  },
  {
    title: "Equity Less Prizes & Funding",
    description: "Get an equity less price of 20k USDC along with grants worth 12 lakh rupees for top 3 startups and more opportunities.",
    icon: "https://user-images.githubusercontent.com/69129797/170815947-30a7c194-fbe0-41d4-aab9-1835c0e4fd34.png",
  }
];

export default CardContent;