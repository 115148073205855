import * as React from 'react'
import {
    wb3heading,
    wb3text,
    wb3cont
  } from "./web3text.module.css";

const Web3text = (props) => {
    return (
        <div className={wb3cont}>
            <div className={wb3heading}>{props.heading}</div>
            <div className={wb3text}>{props.text}</div>
        </div>
    )
}

export default Web3text;