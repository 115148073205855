// extracted by mini-css-extract-plugin
export var parentFooter = "web3Footer-module--parentFooter--f+V+I";
export var footerContainer = "web3Footer-module--footerContainer--9nJ3y";
export var brandingContainer = "web3Footer-module--brandingContainer--B-dwW";
export var logo = "web3Footer-module--logo--9FU+X";
export var inquiryForm = "web3Footer-module--inquiryForm--yb6ih";
export var inquiryInput = "web3Footer-module--inquiryInput--pRD6j";
export var inquiryButton = "web3Footer-module--inquiryButton--fBx6p";
export var arrow = "web3Footer-module--arrow--znsHC";
export var quickLinksContainer = "web3Footer-module--quickLinksContainer--QxyLf";
export var learnMoreContainer = "web3Footer-module--learnMoreContainer--Czqot";
export var footerLink = "web3Footer-module--footerLink--5ecJ+";
export var socialIconContainer = "web3Footer-module--socialIconContainer--WRuxO";
export var socialLink = "web3Footer-module--socialLink--sXoF6";
export var credits = "web3Footer-module--credits--pFBY+";
export var linksParent = "web3Footer-module--linksParent--zNQLI";