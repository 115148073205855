import React from "react";
import {
  cardContainer,
  cardTitleContent,
  cardIcon,
} from "./FeatureCard.module.css";


export default function FeatureCard(props) {
  return (
    <div className={cardContainer}>
        <img className={cardIcon} src={props.icon} alt=""></img>
      <div className={cardTitleContent}>
        <h1>{props.title}</h1>
        <p>
          {props.description}
        </p>
      </div>
    </div>
  );
}
