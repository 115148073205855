export const menuItems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Our Process",
    link: "/process",
  },
  
  {
    title: "Partners",
    submenu: [
      {
        title: "Sponsors and Partners",
        link: "/sponsors-and-partners",
      },
      {
        title: "Resource Partners",
        link: "/resource-partners",
      },
      {
        title: "In the News",
        link: "/news",
      },
    ],
  },
  {
    title: "Network",
    submenu: [
      {
        title: "Alumni",
        link: "/alumni",
      },
      {
        title: "Angels & Jury",
        link: "/angels-jury",
      },
      {
        title: "Mentors",
        link: "/mentors",
      },
    ],
  },
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Web3 Track",
    link: "/web3",
  },
  {
    title: "Newsletter",
    link: "/newsletter",
  },
  
];
