import * as React from "react";

import {
  timelineContainer,
  timelineText,
  timelineTextLeft,
  timelineHeading,
  timelinesubHeading,
  timelineScroller,
  timelineInsideScroll,
  timelineWrapper,
} from "./Timeline.module.css";

import { indicatorParent, indicator } from "../DemoDay/DemoDay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import TimelineItem from "./TimelineItem";

const slideRight = () => {
  // for non-smooth scroll
  // document.getElementById('container').scrollLeft += 150;

  // for smoother scroll
  var container = document.getElementById("timelineInsideScroll");
  console.log(container);
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    container.scrollLeft += 2000;
    scrollAmount += 2000;
    if (scrollAmount >= 2000) {
      window.clearInterval(slideTimer);
    }
  }, 25);
};

const slideLeft = () => {
  // for non-smooth scroll
  // document.getElementById('container').scrollLeft -= 150;

  // for smoother scroll
  var container = document.getElementById("timelineInsideScroll");
  console.log(container);
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    container.scrollLeft -= 2000;
    scrollAmount += 2000;
    if (scrollAmount >= 2000) {
      window.clearInterval(slideTimer);
    }
  }, 25);
};

const Timeline = () => {
  return (
    <div className={timelineWrapper}>
      <div className={timelineContainer}>
        <div className={timelineText}>
          <div className={timelineTextLeft}>
            <p className={timelineHeading}>timeline</p>
            <p className={timelinesubHeading}>
              a timeline of all the events we’ll be having during Conquest
            </p>
          </div>
          <div className={indicatorParent}>
            <button className={indicator} onClick={slideLeft}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className={indicator} onClick={slideRight}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
        <div className={timelineScroller}>
          <div id="timelineInsideScroll" className={timelineInsideScroll}>
            <TimelineItem
              no="01"
              heading="Registration Begins"
              desc="Conquest would be accepting registrations from 3rd April and would go on till 14th June. The registration is free of charge, just fill out a simple hassle-free application form, and voila! you’re good to go."
              date="3rd April - 14th June"
            />
            <TimelineItem
              no="02"
              heading="Mentorship Sessions"
              desc="Based on parameters like the idea, team, USP, market and competition, we shortlist startups. The top startups begin their mentoring journey marked by one-on-one sessions with experts from the entrepreneurial world across different verticals like customer acquisition, product, growth, tech, fundraising, design and sales and marketing."
              date="27th June - 4th July"
            />
            <TimelineItem
              no="03"
              heading="Online Mentoring Program"
              desc="Transcending the boundaries of geography and time, our top startups get an opportunity to receive online mentoring over a 6 week period. Each session revolves around a critical aspect of startup growth, ranging across pitching, fundraising, building MVP, GTM Strategy and finding a product-market fit."
              date="8th July - 7th August"
            />
            <TimelineItem
              no="04"
              heading="Accelerator Program"
              desc="The top startups move to Bangalore for a 7-day accelerator program where they are provided free accommodation and co-working space. We create an environment of co-learning that helps startups grow together at an exponential pace. The program comprises sessions with successful founders, investment workshops by VCs and Angels and networking events."
              date="14th August - 19th August"
            />
            <TimelineItem
              no="05"
              heading="Demo Day"
              desc="Demo Day provides startups with the perfect stage to pitch in front of investors, industry leaders, policymakers and media houses to #MakeAMark. The finale is a day where all the stakeholders of the startup ecosystem come together, engage in thought-provoking conversations, share ideas and create solutions."
              date="21st August"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
