import * as React from "react";
import {
  wcontainer,
  wcircles,
  wcontent,
  wsol,
  whead,
  wsub,
  wreg,
  wconq,
  orangeColour,
  wpartner,
  wb3h,
  wbuild,
} from "./web3hero.module.css";

const Web3hero = () => {
  return (
    <div className={wcontainer}>
      <img className={wcircles} src="https://i.imgur.com/bpagINO.png" />

      <div className={wcontent}>
        <img
          className={wsol}
          src="https://user-images.githubusercontent.com/69129797/166976592-e3f398bc-8864-4389-9a1a-979b0ed7ff36.png"
        />
        <div className={whead}>
          <span>Accelerating the </span> <br></br>
          <span className={orangeColour}>web3.0</span>
          <span> ecosystem</span>
        </div>
        <div className={wsub}>
          <p>
            India’s Largest Student-Run Startup Accelerator. Enabling Founders
            and Learners with the best of Mentorship and Resources.{" "}
          </p>
          <p>
            {" "}
            <b> Apply for the next cohort by 14th June.</b>
          </p>
        </div>
        <a
          href="https://lu.ma/demo-day-conquest22"
          target="_blank"
          rel="noreferrer"
        >
          <button className={wreg}>get your tickets</button>
        </a>
        <img className={wconq} src="https://i.imgur.com/YzCL9u1.png" />

        <div className={wpartner}>
          <div className={wb3h}>web3 track partner</div>
          <img
            className={wbuild}
            src="https://user-images.githubusercontent.com/69129797/166976598-5c08f66a-8220-46a9-8e3b-d3eb17bc85a6.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Web3hero;
